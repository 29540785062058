import { usePage } from '@mediashop/app/hooks/usePage';
import { useEffect, useMemo, useState } from 'react';
import { injectComponent } from '@mediashop/app/component-injector';
import BackgroundColorWrapper from '@mediashop/base/pattern/atom/BackgroundColorWrapper';
import ContentWrapper from '@mediashop/base/pattern/atom/ContentWrapper';
import { Location, useLocation } from 'react-router';
import { ProductGridCategoryList } from './ProductGridCategoryList';
import { ProductGridSearch } from './ProductGridSearch';
import {
    BaseProps,
    BrxRelationType,
    BrxImageWithAltText,
    BrxHeadlines,
    BrxAnchor,
    BrxFilter,
    ContentBackgroundProps,
    BrxBackground,
} from '@mediashop/app/bloomreach/types';
import { ProductGridStandalone } from './ProductGridStandalone';
import { ProductGridExitPage } from './ProductGridExitPage';
import { PRODUCT_GRID_PAGE_LIMIT } from '@mediashop/app/constants/api';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';

type State = 'CATEGORY_LIST' | 'EXIT_PAGE' | 'SEARCH' | 'STANDALONE';

const determineState = (
    pageType: string,
    location: Location,
    productFilters?: Array<BrxFilter>,
    productIDs?: Array<string>
): State => {
    const isSearchPage = pageType === 'search-results-contentpage' || location.pathname.includes('search-results');
    const isCategoryPage = location.pathname?.includes('/c/') && !productFilters?.length && !productIDs?.length;
    const isExitPage = location.pathname?.endsWith('/exit');

    if (isSearchPage) {
        return 'SEARCH';
    } else if (isCategoryPage) {
        return 'CATEGORY_LIST';
    } else if (isExitPage) {
        return 'EXIT_PAGE';
    }

    return 'STANDALONE';
};

const componentName = 'product-grid';

export type SpecialDealProps = {
    backgroundcolor?: string;
    desktopImage?: BrxImageWithAltText;
    bordercolorHex?: string;
    enddate?: number;
    fontcolor?: string;
    headlines?: BrxHeadlines;
    id?: string;
    mobileImage?: BrxImageWithAltText;
    productId?: string;
    specialDealHeaderTransparency?: boolean;
    specialDealPosition?: string;
    specialDealShowCountdown?: boolean;
    specialDealShowStarRating?: boolean;
    startdate?: number;
    trackingValue?: string;
};

interface ProductGridProps extends BaseProps {
    anchor?: BrxAnchor;
    background?: BrxBackground;
    headlineFontColorHex?: string;
    headlines?: BrxHeadlines;
    showHeadline: boolean | null;
    specialDeal?: SpecialDealProps[];
    productIDs?: string[];
    productRelations?: BrxRelationType;
    filter?: BrxFilter[];
    productsPerRowDesktop?: string;
    productsPerPage: string | null;
    productsPerPageDesktop?: string;
    productsPerPageMobile?: string;
    contentBackground?: ContentBackgroundProps;
    maxProductCardCountDesktop?: string;
    maxProductCardCountMobile?: string;
}

/**
 * Displays the Product Grid
 *
 * @constructor
 */

function ProductGrid({
    anchor,
    background,
    headlineFontColorHex: headlineFontColor,
    headlines,
    showHeadline,
    productIDs,
    productRelations: relationType = 'upsell_upgrade',
    productsPerRowDesktop,
    specialDeal,
    filter: productFilters,
    productsPerPage,
    productsPerPageDesktop,
    productsPerPageMobile,
    contentBackground,
    maxProductCardCountDesktop,
    maxProductCardCountMobile,
}: Readonly<ProductGridProps>): JSX.Element {
    const location = useLocation();
    const { pageType } = usePage();
    const [device, setDevice] = useState('desktop');
    const deviceType = useDeviceType();
    const isMobileDevice = device === 'mobile';

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const [searchQuery, setSearchQuery] = useState(searchParams.get('q') ?? '');

    useEffect(() => {
        setSearchQuery(searchParams.get('q') ?? '');
    }, [location.search, searchParams]);

    const limit = useMemo(() => {
        let productLimit = productsPerPage;
        if (isMobileDevice && productsPerPageMobile) {
            productLimit = productsPerPageMobile;
        } else if (productsPerPageDesktop) {
            productLimit = productsPerPageDesktop;
        }

        return parseInt(productLimit ?? PRODUCT_GRID_PAGE_LIMIT.toString(), 10);
    }, [isMobileDevice, productsPerPage, productsPerPageDesktop, productsPerPageMobile]);

    const state = useMemo(
        () => determineState(pageType, location, productFilters, productIDs),
        [pageType, location, productFilters, productIDs]
    );

    const currentDate = new Date();
    const specialDealData = specialDeal?.find((deal) => {
        const startDate = deal.startdate ? new Date(deal.startdate) : deal.startdate;
        const endDate = deal.enddate ? new Date(deal.enddate) : deal.enddate;

        return (
            (!startDate && endDate && endDate >= currentDate) ||
            (startDate && startDate <= currentDate && !endDate) ||
            (startDate && startDate <= currentDate && endDate && endDate >= currentDate)
        );
    });

    return (
        <BackgroundColorWrapper
            backgroundColor={background?.color}
            hexBackgroundColor={background?.backgroundColorHex}
            className={background?.color ? '' : `${componentName}__color-wrapper`}
        >
            <ContentWrapper id={anchor?.id}>
                {state === 'CATEGORY_LIST' && (
                    <ProductGridCategoryList
                        headlineFontColor={headlineFontColor}
                        headlines={headlines}
                        showHeadline={Boolean(showHeadline)}
                        productRelationType={relationType}
                        limit={limit}
                        specialDealData={specialDealData}
                        contentBackground={contentBackground}
                    />
                )}
                {state === 'SEARCH' && (
                    <ProductGridSearch
                        query={searchQuery}
                        key={searchQuery}
                        productRelationType={relationType}
                        limit={limit}
                        contentBackground={contentBackground}
                    />
                )}
                {state === 'STANDALONE' && (
                    <ProductGridStandalone
                        key={location.pathname}
                        headlineFontColor={headlineFontColor}
                        headlines={headlines}
                        productIDs={productIDs ?? []}
                        productFilters={productFilters}
                        productRelationType={relationType}
                        productsPerRowDesktop={productsPerRowDesktop}
                        specialDealData={specialDealData}
                        limit={productsPerPage ? limit : 1000}
                        contentBackground={contentBackground}
                        isMobileDevice={isMobileDevice}
                        maxProductCardCountDesktop={
                            maxProductCardCountDesktop ? Number(maxProductCardCountDesktop) : undefined
                        }
                        maxProductCardCountMobile={
                            maxProductCardCountMobile ? Number(maxProductCardCountMobile) : undefined
                        }
                    />
                )}
                {state === 'EXIT_PAGE' && <ProductGridExitPage />}
            </ContentWrapper>
        </BackgroundColorWrapper>
    );
}

export default injectComponent('pattern.molecule.ProductGrid', ProductGrid);
