import { useEffect, useState } from 'react';
import classNames from 'classnames';
import noop from 'lodash/noop';
import { Product as ProductType, ProductWithActiveVariant } from '@mediashop/app/api/types/ClientProduct';
import { BaseProps, BrxRelationType, ContentBackgroundProps } from '@mediashop/app/bloomreach/types';
import Product from '../../Product';
import { SpecialDealProps } from '..';
import SpecialDeal from '../../SpecialDeal';
import { SKIP_RENDER } from '@mediashop/app/constants/semanticConstants';
import useDeviceType from '@mediashop/app/hooks/useDeviceType';
import { CroppedImageVariant } from '@mediashop/base/types/imageVariants';
import { calculateDealPosition } from './calculateDealPosition';
import { BreakpointName } from '@mediashop/app/config/breakpoints';
import { getVariant } from '../../Product/helper';

const componentName = 'product-grid';

type TestingProps = {
    currentDate?: Date;
};

type GridProps = BaseProps &
    TestingProps & {
        products: ProductWithActiveVariant[];
        productRelationType?: BrxRelationType;
        onProductClick?: (product: ProductType, position: number) => void;
        productsPerRowDesktop?: string;
        specialDealData?: SpecialDealProps;
        specialDealProduct?: ProductWithActiveVariant;
        contentBackground?: ContentBackgroundProps;
        isExitGrid?: boolean;
    };

function Grid({
    className,
    isExitGrid = false,
    products,
    productRelationType,
    onProductClick = noop,
    productsPerRowDesktop,
    specialDealData,
    specialDealProduct,
    currentDate = new Date(),
    contentBackground,
}: GridProps): JSX.Element {
    const {
        specialDealPosition,
        enddate,
        backgroundcolor,
        bordercolorHex,
        desktopImage,
        fontcolor,
        specialDealHeaderTransparency,
        headlines,
        mobileImage,
        specialDealShowCountdown,
        startdate,
        specialDealShowStarRating,
        trackingValue,
    } = specialDealData ?? {};

    const deviceType = useDeviceType();

    const [device, setDevice] = useState<BreakpointName>('desktop');

    const [disableAllButtons, setDisableAllButtons] = useState(false);

    useEffect(() => {
        setDevice(deviceType);
    }, [deviceType]);

    const initialPosition = specialDealPosition ? Number(specialDealPosition) : 1;
    const productsPerRowDesktopNumber = productsPerRowDesktop ? Number(productsPerRowDesktop) : 5;
    const dealPosition = calculateDealPosition(device, initialPosition, products.length, productsPerRowDesktopNumber);

    const hasStartOrEndDate = Boolean(startdate) || Boolean(enddate);
    const hasCountdownStarted = !startdate || new Date(startdate) <= currentDate;
    const hasCountdownEnded = enddate && new Date(enddate) < currentDate;

    const renderSpecialDealInGrid =
        Boolean(specialDealProduct) && hasStartOrEndDate && hasCountdownStarted && !hasCountdownEnded;

    const handleDisableAllButtons = (disable: boolean) => {
        setDisableAllButtons(disable);
    };

    return (
        <div
            className={classNames(
                `${componentName}--container`,
                className,
                `${componentName}--container--col-${productsPerRowDesktopNumber}`
            )}
        >
            {/** Special deal */}
            {renderSpecialDealInGrid ? (
                <div className={`${componentName}__special-deal`} style={{ order: dealPosition }}>
                    <SpecialDeal
                        customBackgroundColor={backgroundcolor}
                        backgroundColorBottom={contentBackground?.color}
                        backgroundColorHexBottom={contentBackground?.backgroundColorHex}
                        customFontColor={fontcolor}
                        bordercolorHex={bordercolorHex}
                        endDate={enddate}
                        desktopImage={desktopImage}
                        headlines={headlines}
                        mobileImage={mobileImage}
                        product={specialDealProduct!}
                        productRelationType={productRelationType}
                        showCountdown={specialDealShowCountdown}
                        hasTransparentHeader={specialDealHeaderTransparency}
                        showSpecialDealStarRating={specialDealShowStarRating}
                        activeVariant={specialDealProduct!.activeVariant}
                        trackingValue={trackingValue}
                    />
                </div>
            ) : (
                SKIP_RENDER
            )}

            {/** Products */}
            {products.map((product, index) => (
                <div
                    className={classNames(`${componentName}__product-element`, 'background--default')}
                    key={`${product.activeVariant?.sku ?? product.variants[0].sku}}`}
                    style={{ order: index + 1 }}
                >
                    <div className={`${componentName}__content-container`}>
                        <Product
                            roundedImages
                            product={product}
                            status="succeeded"
                            description={getVariant(product)?.attributes.claim}
                            onClick={() => onProductClick(product, index + 1)}
                            productRelationType={productRelationType}
                            imageVariant={CroppedImageVariant[`ProductGrid${productsPerRowDesktopNumber}`]}
                            disabled={disableAllButtons}
                            handleDisableAllButtons={handleDisableAllButtons}
                            backgroundColor={contentBackground?.color}
                            backgroundColorHex={contentBackground?.backgroundColorHex}
                            isExitGrid={isExitGrid}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
}

export default Grid;
